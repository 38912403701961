import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  ANNOTATE_VIDEO_REQUEST,
  ANNOTATE_VIDEO_SUCCESS,
  ANNOTATE_VIDEO_FAILURE,
} from '@/store/mutation-types'

export default app => {
  // INIT
  const api = app.config.globalProperties.$api

  // STATE - default
  let initialState = {
    annotateVideosOutput: null,
    status: {
      annotateVideos: null,
    },
  }
  // STATE - init
  const state = JSON.parse(JSON.stringify(initialState))

  // GETTERS
  const getters = {
    apiNames: function () {
      return ["vca", "calipsa"]
    },
    annotateVideosOutput: function (state) {
      return state.annotateVideosOutput ?? {}
    },
    annotateVideoOutput: function (state, getters) {
      return function(videoName) {
        return getters.annotateVideosOutput[videoName]
      }
    },
    statusAnnotateVideos: function (state) {
      return state.status.annotateVideos ?? {}
    },
    statusAnnotateVideo: function (state, getters) {
      return function(videoName) {
        return getters.statusAnnotateVideos[videoName] ?? ""
      }
    },
  }

  // ACTIONS
  const actions = {
    annotateVideo: function ({commit}, {videoName, inputContent, features}) {
      commit(ANNOTATE_VIDEO_REQUEST, {videoName})
      return new Promise((resolve, reject) => {
        api.annotateVideo({videoName, inputContent, features}).then(
          res => {
            // {operation_id, video_uid, video_info}
            commit(ANNOTATE_VIDEO_SUCCESS, {videoName, data: res.data})
            resolve(res.data)
            commit(PROCESS_API_SUCCESS)
          },
          err => {
            commit(ANNOTATE_VIDEO_FAILURE, {videoName})
            if (err.response === undefined) {
              reject(err.message)
              return
            }
            reject(err.response)
            commit(PROCESS_API_FAILURE, {
              status: err.response.status,
              statusText: err.response.data.message,
            })
          }
        )
      })
    },
  }

  // MUTATIONS
  const mutations = {
    [RESET_STATE]: function (state) {
      for (let f in state) {
        state[f] = initialState[f]
      }
    },
    [ANNOTATE_VIDEO_REQUEST]: function (state, {videoName}) {
      if (!state.status.annotateVideos) state.status.annotateVideos = {}
      state.status.annotateVideos[videoName] = 'requested'
    },
    [ANNOTATE_VIDEO_SUCCESS]: function (state, {videoName, data}) {
      if (!state.annotateVideosOutput) state.annotateVideosOutput = {}
      state.annotateVideosOutput[videoName] = data
      if (!state.status.annotateVideos) state.status.annotateVideos = {}
      state.status.annotateVideos[videoName] = 'successful'
    },
    [ANNOTATE_VIDEO_FAILURE]: function (state, {videoName}) {
      if (!state.status.annotateVideos) state.status.annotateVideos = {}
      state.status.annotateVideo[videoName] = 'failed'
    },
  }

  // RETURN
  return {
    state,
    getters,
    actions,
    mutations
  }
}
