import { createStore } from 'vuex'
import rootModule from './rootModule'

import operations from './modules/operations'
import videos from './modules/videos'


export default app => {
  const debug = !app.config.globalProperties.$env.isProduction()
  return createStore({
    state: rootModule.state,
    actions: rootModule.actions,
    getters: rootModule.getters,
    mutations: rootModule.mutations,
    modules: {
      operations: operations(app),
      videos: videos(app),
    },
    strict: debug
  })
}
