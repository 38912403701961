import videoai from '@/apis/videoai'

const api = {
  install (app) {
    app.config.globalProperties.$api = {
      ...videoai(app),
    }
  }
}

export default api
