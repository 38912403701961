import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_OPERATION_REQUEST,
  GET_OPERATION_SUCCESS,
  GET_OPERATION_FAILURE,
} from "@/store/mutation-types"

export default app => {
  // INIT
  const api = app.config.globalProperties.$api

  // STATE - default
  let initialState = {
    operations: null,
    status: {
      getOperations: null,
    },
  }
  // STATE - init
  const state = JSON.parse(JSON.stringify(initialState))

  // GETTERS
  const getters = {
    operations: function (state) {
      return state.operations ?? {}
    },
    operation: function (state, getters) {
      return function(operationId) {
        return getters.operations[operationId]
      }
    },
    annotationReports: function (state, getters) {
      return function(operationId) {
        const operation = getters.operation(operationId)
        if (!(operation instanceof Object)) return undefined
        return operation.reports
      }
    },
    annotationReport: function (state, getters) {
      return function(operationId, apiName) {
        if (!operationId) return undefined
        if (!apiName) return undefined
        const reports = getters.annotationReports(operationId)
        if (!(reports instanceof Object)) return undefined
        const filteredList = Object.values(reports).filter(v=>{
          const feature = String(v.feature).toLowerCase()
          return feature.includes(String(apiName).toLowerCase())
        })
        return filteredList.length > 0 ? filteredList[0] : undefined
      }
    },
    annotationProgress: function (state, getters) {
      return function(operationId, apiName) {
        const report = getters.annotationReport(operationId, apiName)
        if (!(report instanceof Object)) return {}
        return report.progress
      }
    },
    annotationReportDetections: function (state, getters) {
      return function(operationId, apiName) {
        const report = getters.annotationReport(operationId, apiName)
        if (!(report instanceof Object)) return []
        const detections = report.detections
        if (!(detections instanceof Array)) return []
        return report.detections
      }
    },
    annotationReportDetectedEntities: function (state, getters) {
      return function(operationId, apiName) {
        const detections = getters.annotationReportDetections(operationId, apiName)
        if (!(detections instanceof Array)) return {}
        const entities = {}
        detections.forEach(v=>{
          // if (v.confidence.avg < 0.8) return
          const entity = v.entity.description
          if (!(entity in entities)) entities[entity] = 0
          entities[entity]++
        })
        return entities
      }
    },
    detectedObjectIds: function (state, getters) {
      return function(operationId, apiName) {
        const detections = getters.annotationReportDetections(operationId, apiName)
        if (!(detections instanceof Array)) return []
        return Object.keys(detections)
      }
    },
    
    statusGetOperations: function (state) {
      return state.status.getOperations ?? {}
    },
    statusGetOperation: function (state, getters) {
      return function(operationId) {
        return getters.statusGetOperations[operationId] ?? ""
      }
    },
  }

  // ACTIONS
  const actions = {
    getOperation: function ({commit}, {operationId}) {
      commit(GET_OPERATION_REQUEST, {operationId})
      return new Promise((resolve, reject) => {
        api.getOperation({operationId}).then(
          res => {
            commit(GET_OPERATION_SUCCESS, {operationId, data: res.data})
            resolve(res.data)
            commit(PROCESS_API_SUCCESS)
          },
          err => {
            commit(GET_OPERATION_FAILURE, {operationId})
            if (err.response === undefined) {
              reject(err.message)
              return
            }
            reject(err.response)
            commit(PROCESS_API_FAILURE, {
              status: err.response.status,
              statusText: err.response.data.message,
            })
          }
        )
      })
    },
  }

  // MUTATIONS
  const mutations = {
    [RESET_STATE]: function (state) {
      for (let f in state) {
        state[f] = initialState[f]
      }
    },
    [GET_OPERATION_REQUEST]: function (state, {operationId}) {
      if (!state.status.getOperations) state.status.getOperations = {}
      state.status.getOperations[operationId] = "requested"
    },
    [GET_OPERATION_SUCCESS]: function (state, {operationId, data}) {
      if (!state.operations) state.operations = {}
      state.operations[operationId] = data
      if (!state.status.getOperations) state.status.getOperations = {}
      state.status.getOperations[operationId] = "successful"
    },
    [GET_OPERATION_FAILURE]: function (state, {operationId}) {
      if (!state.status.getOperations) state.status.getOperations = {}
      state.status.getOperations[operationId] = "failed"
    },
  }

  // RETURN
  return {
    state,
    getters,
    actions,
    mutations
  }
}
