export default app => {
  const v = app.config.globalProperties
  return {
    annotateVideo: function ({inputContent, features}) {
      return v.$http({
        method: 'post',
        baseURL: v.$env.getVideoAIAPIPrefixV1(),
        url: "/videos:annotate",
        data: {
          inputContent,
          features,
        },
        responseType: 'json',
        timeout: 1000 * 10,
      })
    },
  }
}
