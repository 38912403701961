<template lang="pug">
.absolute(
  @mousemove="onMouseMove"
  @mousedown="onMouseDown"
  ref="activity-annotation"
  style="top:0;left:0;")
  .absolute(
    style="border: 2px solid orange;background-color:hsla(40,100%,50%,50%);cursor:pointer;"
    @click="onClickBox"
    :style="boxStyle(i)"
    v-for="(v, i) in boxes")
</template>

<style>
</style>

<script>
export default {
  props: {
    currentFrame: {
      type: Number,
      default: 0,
    },
    annotate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    boxStyle: function () {
      return function (index) {
        const box = this.boxes?.[index]
        if (!box) {
          return {
            top: -1,
            left: -1,
            width: 0,
            height: 0,
          }
        }
        return {
          top: `${box.top*100}%`,
          left: `${box.left*100}%`,
          width: `${box.width*100}%`,
          height: `${box.height*100}%`,
        }
      }.bind(this)
    },
  },
  data: function () {
    return {
      isMouseDown: false,
      boxes: [
        {
          top: -1,
          left: -1,
          width: 0,
          height: 0,
        }
      ],
    }
  },
  watch: {
  },
  mounted: function () {
    window.addEventListener('mouseup', this.onMouseUp)
  },
  beforeUnmount: function () {
    window.removeEventListener('mouseup', this.onMouseUp)
  },
  methods: {
    onClickBox: function (e) {
      if (this.annotate) return
      e.stopPropagation()
      this.$q.dialog({
        title: 'Remove Annotation',
        message: 'Would you like to remove the annotation?',
        cancel: true,
        persistent: false
      }).onOk(() => {
        this.resetBoxes()
      })
    },
    resetBoxes: function() {
      this.boxes = [
        {
          top: -1,
          left: -1,
          width: 0,
          height: 0,
        }
      ]
    },
    onMouseUp: function() {
      this.isMouseDown = false
    },
    onMouseDown: function(e) {
      if (!this.annotate) return
      this.isMouseDown = true
      const {x, y, width, height}= this.getCoordinates(e)
      const box = this.boxes?.[0]
      if (!box) return
      box.top = y / height
      box.left = x / width
      this.boxes.shift()
      this.boxes.push(box)
    },
    onMouseMove: function (e) {
      if (!this.isMouseDown) return
      const {x, y, width, height}= this.getCoordinates(e)
      const box = this.boxes?.[0]
      if (!box) return
      box.width = (x / width) - box.left
      box.height = (y / height) - box.top
      this.boxes.shift()
      this.boxes.push(box)
    },
    getCoordinates: function (e) {
      if (!(this.$refs['activity-annotation'] instanceof Object)) return {x:0, y:0, width:0, height:0}
      const rect = this.$refs['activity-annotation'].getBoundingClientRect()
      const x = e.clientX - rect.left ?? 0
      const y = e.clientY - rect.top ?? 0
      const width = rect.width
      const height = rect.height
      return {x, y, width, height}
    },
  },
}
</script>
