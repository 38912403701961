<template lang="pug">
.absolute(
  style="top:0;left:0;")
  .absolute(
    style="background-color: rgba(255,255,255,0.3);"
    :style="{top: v.top, left: v.left, width: v.width, height: v.height, border: v.border}"
    v-for="v in boxes")
    .absolute.text-body2(:style="{color: v.color}") {{v.id}}: {{v.class}}
</template>

<style>
</style>

<script>
export default {
  props: {
    currentFrame: {
      type: Number,
      default: 0,
    },
    file: {
      type: File,
      default: null,
    },
    apiName: {
      type: String,
      default: "vca",
    },
  },
  computed: {
    apiNames: function () {
      return this.$store.getters.apiNames
    },
    annotateVideoOutput: function () {
      return this.$store.getters.annotateVideoOutput
    },
    operationId: function () {
      const output = this.annotateVideoOutput(this.fileName)
      if (!(output instanceof Object)) return ""
      const { operation_id } = output
      return operation_id
    },
    annotationReportDetections: function () {
      return this.$store.getters.annotationReportDetections(this.operationId, this.apiName)
    },
    annotationReportDetectedEntities: function () {
      return this.$store.getters.annotationReportDetectedEntities(this.operationId, this.apiName)
    },
    annotationProgress: function () {
      return this.$store.getters.annotationProgress
    },
    statusAnnotateVideo: function () {
      return this.$store.getters.statusAnnotateVideo(this.fileName)
    },
    statusGetOperation: function () {
      return this.$store.getters.statusGetOperation
    },
  },
  data: function () {
    return {
      fileName: "",
      boxes: [],
      refreshingOperations$i: null,
    }
  },
  watch: {
    file: function () {
      this.updateFileName()
      this.annotateVideo()
    },
    currentFrame: function () {
      this.renderBoxes()
    },
    annotationReportDetections: function () {
      this.renderBoxes()
    },
  },
  mounted: function () {
    this.updateFileName()
    this.annotateVideo()
    this.startRefreshingOperations()
    this.renderBoxes()
  },
  beforeUnmount: function () {
    this.stopRefreshingOperations()
  },
  methods: {
    renderBoxes: function () {
      const detections = this.annotationReportDetections
      const currentFrame = this.currentFrame.toString()
      const boxes = []
      for (let i=0; i < detections.length; i++) {
        const detection = detections[i]
        const entity = detection["entity"]["description"]
        const box = detection.frames[currentFrame]
        if (!(box instanceof Object)) continue
        boxes.push({
          id: i+1,
          class: entity,
          color: entity === "person" || entity === "vehicle" ? "red" : "blue",
          border: `1px solid ${entity === "person" || entity === "vehicle" ? "red" : "blue"}`,
          confidence: detection["confidence"]["avg"],
          top: `${box["top_y"] * 100}%`,
          left: `${box["top_x"] * 100}%`,
          width: `${(box["bottom_x"] - box["top_x"]) * 100}%`,
          height: `${(box["bottom_y"] - box["top_y"]) * 100}%`,
        })
      }
      this.boxes = boxes
    },
    annotateVideo: async function () {
      if (!this.file) return
      if (!this.fileName) return
      if (this.statusAnnotateVideo === "requested") return
      if (this.statusAnnotateVideo === "successful") return
      try {
        const inputContent = await this.$tool.fileToBase64(this.file)
        const features = ["VCA_OBJECT_TRACKING_V2", "CALIPSA_OBJECT_DETECTION_V1"]
        await this.$store.dispatch("annotateVideo", {videoName: this.fileName, inputContent, features})
      } catch (err) {
        console.warn(`annotateVideo FAILED: ${this.fileName}`)
      }
    },
    startRefreshingOperations: function () {
      this.stopRefreshingOperations()
      this.getOperations()
      this.refreshingOperations$i = setInterval(()=>{
        this.getOperations()
      }, 1000*2)
    },
    stopRefreshingOperations: function () {
      if (this.refreshingOperations$i) clearInterval(this.refreshingOperations$i)
      this.refreshingOperations$i = null
    },
    getOperations: function () {
      this.apiNames.forEach(v => {
        this.getOperation(v)
      })
    },
    getOperation: async function (apiName) {
      if (!this.file) return
      if (!this.fileName) return
      const operationId = this.operationId
      if (!operationId) return
      if (this.statusGetOperation(operationId) === "requested") return
      const { status } = this.annotationProgress(operationId, apiName)
      if (status === "analyzed") return
      if (status === "unanalyzed") return
      try {
        await this.$store.dispatch("getOperation", {operationId})
      } catch (err) {
        console.warn(`getOperation FAILED: ${this.fileName} ${operationId}`)
      }
    },
    updateFileName: function () {
      if (!this.file) return
      this.fileName = this.file.name
    },
  },
}
</script>
