export default app => {
  const v = app.config.globalProperties
  return {
    getOperation: function ({operationId}) {
      return v.$http({
        method: 'get',
        baseURL: v.$env.getVideoAIAPIPrefixV1(),
        url: `/operations/${operationId}`,
        responseType: 'json',
        timeout: 1000 * 10,
      })
    },
  }
}
