//////////
// ROOT //
//////////
export const RESET_STATE = 'RESET_STATE'
export const PROCESS_API_SUCCESS = 'PROCESS_API_SUCCESS'
export const PROCESS_API_FAILURE = 'PROCESS_API_FAILURE'

////////////////////
// ANNOTATE VIDEO //
////////////////////
export const ANNOTATE_VIDEO_REQUEST = 'ANNOTATE_VIDEO_REQUEST'
export const ANNOTATE_VIDEO_SUCCESS = 'ANNOTATE_VIDEO_SUCCESS'
export const ANNOTATE_VIDEO_FAILURE = 'ANNOTATE_VIDEO_FAILURE'

////////////////
// OPERATIONS //
////////////////
export const GET_OPERATION_REQUEST = 'GET_OPERATION_REQUEST'
export const GET_OPERATION_SUCCESS = 'GET_OPERATION_SUCCESS'
export const GET_OPERATION_FAILURE = 'GET_OPERATION_FAILURE'
