/////////////////////////////////////////////////////////
// CREATE APP
import { createApp } from 'vue'
import App from '@/App.vue'
const app = createApp(App)
/////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////
// PLUG-INs
import router from '@/_routes/_router'
app.use(router)
//
import env from '@/plugins/env'
app.use(env)
//
import auth from '@/plugins/auth'
app.use(auth)
//
import api from '@/plugins/api'
app.use(api)
//
import axios from '@/plugins/axios'
app.use(axios)
//
import tool from '@/plugins/tool'
app.use(tool)
//
import icon from '@/plugins/icon'
app.use(icon)
//
import createStore from '@/store'
app.use(createStore(app))
//
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
app.use(Quasar, quasarUserOptions)
//
import components from '@/plugins/components'
app.use(components)
/////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////
// MOUNT
app.mount('#app')
/////////////////////////////////////////////////////////