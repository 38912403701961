const auth = {
  install (app) {
    let accessToken = 'someAccessToken'
    app.config.globalProperties.$auth = {
      getAccessToken: function () {
        return accessToken
      }
    }
  }
}

export default auth
