<template lang="pug">

//- All
.no-scroll(
  style="position:absolute;top:0;left:0;width:100%;height:100%;")
  //- video
  .no-scroll(style="position:relative;width:100%;height:calc(100% - 80px);")
    video(
      :src="src"
      @play="play=true"
      @pause="play=false"
      @timeupdate="timeupdated=$event"
      autoplay loop
      style="width:100%;height:100%;object-fit:fill;"
      ref="c-video-101")

    Annotation(
      v-if="annotate"
      style="width:100%;height:100%;"
      :currentFrame="currentFrame" :file="file" :apiName="apiName")
    
  //- control
  div.relative-position.bg-black(style="width:100%;height:80px;")
    Slider(
      :video="video"
      :videoInfo="videoInfo"
      :play="play"
      :timeupdated="timeupdated"
      :currentFrame="currentFrame"
      :fileName="fileName"
      :apiName="apiName")

</template>

<style lang="sass">
.q-media--player
  object-fit: fill
</style>

<script>
import Slider from "./comps/Slider.vue"
import Annotation from "./comps/Annotation.vue"

export default {
  components: {
    Slider,
    Annotation,
  },
  props: {
    file: {
      type: File,
      default: null,
    },
    annotate: {
      type: Boolean,
      default: false,
    },
    apiName: {
      type: String,
      default: "vca",
    },
  },
  data: function () {
    return {
      src: "",
      fps: 6,
      fileName: "",
      currentFrame: -1,
      updatingCurrentFrame$i: null,
      video: undefined,
      videoInfo: undefined,
      play: false,
      timeupdated: undefined,
    }
  },
  watch: {
    file: function () {
      this.init()
    },
    fps: function () {
      this.startUpdatingCurrentFrame()
    },
  },
  mounted: function () {
    this.init()
  },
  beforeUnmount: function () {
    this.stopUpdatingCurrentFrame()
  },
  methods: {
    init: function() {
      this.updateVideo()
      this.updateSRC()
      this.updateFPS()
      this.updateVideoInfo()
      this.updateFileName()
      this.startUpdatingCurrentFrame()
    },
    startUpdatingCurrentFrame: function () {
      this.stopUpdatingCurrentFrame()
      this.updatingCurrentFrame$i = setInterval(()=>{
        this.updateCurrentFrame()
      }, 1000/this.fps)
    },
    stopUpdatingCurrentFrame: function () {
      if (!this.updatingCurrentFrame$i) return
      clearInterval(this.updatingCurrentFrame$i)
      this.updatingCurrentFrame$i = null
    },
    //
    updateVideo: function () {
      if (this.video) return
      if (!this.$refs['c-video-101']) return
      this.video = this.$refs['c-video-101']
    },
    updateCurrentFrame: function () {
      if (!(this.$refs['c-video-101'] instanceof Object)) return
      this.currentFrame = Math.floor(this.$refs['c-video-101'].currentTime * this.fps)
    },
    updateFPS: async function () {
      if (!this.file) return
      const info = await this.$tool.parseMP4(this.file)
      this.fps = info.fps
    },
    updateVideoInfo: async function () {
      if (!this.file) return
      const info = await this.$tool.parseMP4(this.file)
      this.fps = info.fps
      this.videoInfo = info
    },
    updateSRC: function () {
      if (!this.file) return
      this.src = URL.createObjectURL(this.file)
    },
    updateFileName: function () {
      if (!this.file) return
      this.fileName = this.file.name
    },
  },
}
</script>
