<template lang="pug">

q-table(
  style="z-index: 0;"
  dense
  :title="`Video List (${files.length}/10)`"
  :rows="rows"
  :columns="columns"
  :pagination="{rowsPerPage: 0}"
  row-key="name")
  template(v-slot:header="props")
    q-tr(:props="props")
      q-th(auto-width)
      q-th(
        v-for="col in props.cols"
        :key="col.name"
        :props="props")
          | {{ col.label }}
  template(v-slot:body="props")
    q-tr.cursor-pointer(
      @click="onRowClick(props.row)"
      :props="props")
      q-td(auto-width)
        q-btn(
          @click.stop="onClickDelete(props.row)"
          icon="delete" size="sm" color="black" round dense)
      q-td(key="name" :props="props")
        | {{ props.row.description }}
      q-td(key="vca" :props="props")
        template(v-if="operationIdOfVideoName(props.row.name)")
          //- CASE 1 - unanalyzed
          template(v-if="annotationStatus(props.row.name, 'vca') === 'unanalyzed'")
            q-badge(color="grey")
              .text-bold X
              q-icon(name="directions_walk" color="white" class="q-ml-xs")
              q-icon(name="directions_car" color="white" class="q-ml-xs")
          //- CASE 2 - analyzing
          template(v-else-if="annotationStatus(props.row.name, 'vca') !== 'analyzed'")
            q-spinner-dots(color="primary" size="2em")
          //- CASE 3 - analyzed
          template(v-else)
            .q-gutter-xs.flex.no-wrap
              //- CASE 3.1 - nothing detected
              template(v-if="annotationReportDetections(operationIdOfVideoName(props.row.name), 'vca').length < 1")
                //- duration
                q-badge(color="primary")
                  .text-bold {{annotationSeconds(props.row.name, 'vca')}}s
                //- label
                q-badge(color="grey")
                  .text-bold X
                  q-icon(name="directions_walk" color="white" class="q-ml-xs")
                  q-icon(name="directions_car" color="white" class="q-ml-xs")
              //- CASE 3.2 - something detected
              template(v-else)
                //- duration
                q-badge(color="primary")
                  .text-bold {{annotationSeconds(props.row.name, 'vca')}}s
                //- label
                q-badge(
                  v-for="(v, k) in annotationReportDetectedEntities(operationIdOfVideoName(props.row.name), 'vca')"
                  color="red")
                  template(v-if="k === 'person'")
                    .text-bold {{v}}
                    q-icon(name="directions_walk" color="white" class="q-ml-xs")
                  template(v-else-if="k === 'vehicle'")
                    .text-bold {{v}}
                    q-icon(name="directions_car" color="white" class="q-ml-xs")
                  template(v-else)
                    .text-bold {{v}} {{k}}
      q-td(key="calipsa" :props="props")
        template(v-if="operationIdOfVideoName(props.row.name)")
          //- CASE 1 - unanalyzed
          template(v-if="annotationStatus(props.row.name, 'calipsa') === 'unanalyzed'")
            q-badge(color="grey")
              .text-bold X
              q-icon(name="directions_walk" color="white" class="q-ml-xs")
              q-icon(name="directions_car" color="white" class="q-ml-xs")
          //- CASE 2 - analyzing
          template(v-else-if="annotationStatus(props.row.name, 'calipsa') !== 'analyzed'")
            q-spinner-dots(color="primary" size="2em")
          //- CASE 3 - analyzed
          template(v-else)
            .q-gutter-xs.flex.no-wrap
              //- CASE 3.1 - nothing detected
              template(v-if="annotationReportDetections(operationIdOfVideoName(props.row.name), 'calipsa').length < 1")
                //- duration
                q-badge(color="primary")
                  .text-bold {{annotationSeconds(props.row.name, 'calipsa')}}s
                //- label
                q-badge(color="grey")
                  .text-bold X
                  q-icon(name="directions_walk" color="white" class="q-ml-xs")
                  q-icon(name="directions_car" color="white" class="q-ml-xs")
              //- CASE 3.2 - something detected
              template(v-else)
                //- duration
                q-badge(color="primary")
                  .text-bold {{annotationSeconds(props.row.name, 'calipsa')}}s
                //- label
                q-badge(
                  v-for="(v, k) in annotationReportDetectedEntities(operationIdOfVideoName(props.row.name), 'calipsa')"
                  color="red")
                  template(v-if="k === 'person'")
                    .text-bold {{v}}
                    q-icon(name="directions_walk" color="white" class="q-ml-xs")
                  template(v-else-if="k === 'vehicle'")
                    .text-bold {{v}}
                    q-icon(name="directions_car" color="white" class="q-ml-xs")
                  template(v-else)
                    .text-bold {{v}} {{k}}
      
</template>

<style>
</style>

<script>
export default {
  props: {
    files: {
      type: Array,
      default: null,
    },
  },
  emits: ['click-file', 'delete-file'],
  computed: {
    apiNames: function () {
      return this.$store.getters.apiNames
    },
    annotateVideoOutput: function () {
      return this.$store.getters.annotateVideoOutput
    },
    operationIdOfVideoName: function () {
      return function(videoName) {
        const output = this.annotateVideoOutput(videoName)
        if (!(output instanceof Object)) return ""
        const { operation_id } = output
        return operation_id
      }
    },
    statusAnnotateVideo: function () {
      return this.$store.getters.statusAnnotateVideo
    },
    annotationProgress: function () {
      return this.$store.getters.annotationProgress
    },
    annotationStatus: function () {
      return function(videoName, apiName) {
        const operation_id = this.operationIdOfVideoName(videoName)
        if (!operation_id) return ""
        const progress = this.annotationProgress(operation_id, apiName)
        if (!progress) return ""
        return progress.status
      }
    },
    annotationSeconds: function () {
      return function(videoName, apiName) {
        const operation_id = this.operationIdOfVideoName(videoName)
        if (!operation_id) return -1
        const progress = this.annotationProgress(operation_id, apiName)
        if (!progress) return -1
        return Math.round(+progress.seconds)
      }
    },
    annotationReportDetections: function () {
      return this.$store.getters.annotationReportDetections
    },
    annotationReportDetectedEntities: function () {
      return this.$store.getters.annotationReportDetectedEntities
    },
    statusGetOperation: function () {
      return this.$store.getters.statusGetOperation
    },
  },
  data: function () {
    return {
      columns: [
        { name: 'name',       label: 'Name',       field: 'name',       align:"left", sortable: true, required: true },
        { name: 'vca',        label: 'VCA',        field: 'vca',        align:"left"},
        { name: 'calipsa',    label: 'Calipsa',    field: 'calipsa',    align:"left" },
      ],
      rows: [],
      refreshingOperations$i: null,
    }
  },
  watch: {
    files: function () {
      this.init()
    },
  },
  mounted: function () {
    this.init()
  },
  beforeUnmount: function () {
    this.stopRefreshingOperations()
  },
  methods: {
    init: function () {
      this.updateRows()
      this.annotateVideos()
      this.startRefreshingOperations()
    },
    updateRows: function () {
      if (!this.files) return
      const rows = []
      this.files.forEach(v => {
        rows.push({
          name: v.name,
          description: v.name.slice(0, 22) + "...",
          file: v,
        })
      })
      this.rows = rows
    },
    onRowClick: function (row) {
      this.$emit("click-file", row.file)
    },
    onClickDelete: function (row) {
      this.$emit("delete-file", row.file)
    },
    annotateVideos: function () {
      if (!this.files) return
      this.files.forEach(v => {
        this.annotateVideo(v)
      })
    },
    annotateVideo: async function (file) {
      if (!file) return
      const videoName = file.name
      if (!videoName) return
      if (this.statusAnnotateVideo(videoName) === "requested") return
      if (this.statusAnnotateVideo(videoName) === "successful") return
      try {
        const inputContent = await this.$tool.fileToBase64(file)
        const features = ["VCA_OBJECT_TRACKING_V2", "CALIPSA_OBJECT_DETECTION_V1"]
        await this.$store.dispatch("annotateVideo", {videoName, inputContent, features})
      } catch (err) {
        console.warn(`annotateVideo FAILED: ${videoName}`)
      }
    },
    startRefreshingOperations: function () {
      this.stopRefreshingOperations()
      this.getOperations()
      this.refreshingOperations$i = setInterval(()=>{
        this.getOperations()
      }, 1000*2)
    },
    stopRefreshingOperations: function () {
      if (this.refreshingOperations$i) clearInterval(this.refreshingOperations$i)
      this.refreshingOperations$i = null
    },
    getOperations: function () {
      if (!this.files) return
      this.files.forEach(v => {
        this.apiNames.forEach(vv => {
          this.getOperation(v, vv)
        })
      })
    },
    getOperation: async function (file, apiName) {
      if (!file) return
      const videoName = file.name
      if (!videoName) return
      const operationId = this.operationIdOfVideoName(videoName)
      if (!operationId) return
      if (this.statusGetOperation(operationId) === "requested") return
      const { status } = this.annotationProgress(operationId, apiName)
      if (status === "analyzed") return
      if (status === "unanalyzed") return
      try {
        await this.$store.dispatch("getOperation", {operationId})
      } catch (err) {
        console.warn(`getOperation FAILED: ${videoName} ${operationId}`)
      }
    },
  },
}
</script>
