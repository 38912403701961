<template lang="pug">

//- slider wrapper
div.relative-position.bg-grey-9.rounded-borders.no-scroll(
  style="width:calc(100% - 40px);height:50px;top:15px;left:20px;")
  //- play/pause wrapper
  div.absolute.flex.flex-center(
    style="width:50px;height:100%;top:0;left:0;")
    q-btn(
      @click="togglePlay"
      :icon="play ? 'pause' : 'play_arrow'"
      dense flat round color="white" size="md")
  //- tracks wrapper
  div.absolute.bg-grey-8.no-scroll.cursor-pointer(
    @click="onClickTracks"
    @mousemove="onMouseMove"
    @mousedown="isMouseDown = true"
    ref="tracks-wrapper"
    style="width:calc(100% - 50px);height:100%;top:0;left:50px;")
    //- track(s)
    div.absolute.bg-grey.rounded-borders(
      v-for="v in markers"
      :style="{width:v.width,height:v.height,top:v.top,left:v.left}")
      q-badge(
        style="position:absolute;top:50%;left:0;transform:translate(-50%,-50%)"
        color="white" rounded)
        q-icon(:name="v.icon" color="red-7" size="xs")
    //- track(s)
    //- div.absolute.bg-grey.rounded-borders(
      style="width:16%;height:34px;top:8px;left:62%;")
      q-badge(
        style="position:absolute;top:50%;left:0;transform:translate(-50%,-50%);"
        color="white" rounded)
        q-icon(name="crop_free" color="red-7" size="xs")
    //- thumb
    div.absolute(
      :style="{left: `calc(${currentTime / duration * 100}% - 5px)`}"
      style="width:10px;height:50px;top:0;background-color:rgba(255,255,255,0.5);")
      div.absolute.bg-red-7(
        style="width:2px;height:50px;top:0;left:4px;")

</template>

<style>
</style>

<script>
export default {
  props: {
    video: {
      type: Object,
      default: undefined,
    },
    videoInfo: {
      type: Object,
      default: undefined,
    },
    play: {
      type: Boolean,
      default: true,
    },
    timeupdated: {
      type: Object,
      default: undefined,
    },
    currentFrame: {
      type: Number,
      default: 0,
    },
    fileName: {
      type: String,
      default: "",
    },
    apiName: {
      type: String,
      default: "vca",
    },
  },
  computed: {
    annotateVideoOutput: function () {
      return this.$store.getters.annotateVideoOutput
    },
    operationId: function () {
      const output = this.annotateVideoOutput(this.fileName)
      if (!(output instanceof Object)) return ""
      const { operation_id } = output
      return operation_id
    },
    annotationReportDetections: function () {
      return this.$store.getters.annotationReportDetections(this.operationId, this.apiName)
    },
  },
  data: function () {
    return {
      duration: 0,
      currentTime: 0,
      totalFrames: 0,
      isMouseDown: false,
      prevPlayStateBeforeMouseDown: true,
      markers: [],
    }
  },
  watch: {
    timeupdated: function () {
      this.onCurrentTimeChange()
    },
    annotationReportDetections: function () {
      this.updateMarkers()
    },
    totalFrames: function () {
      this.updateMarkers()
    },
    isMouseDown: function () {
      if (this.isMouseDown) {
        this.prevPlayStateBeforeMouseDown = this.play
        this.pauseVideo()
        return
      }
      // PLAY again if it was playing
      if (this.prevPlayStateBeforeMouseDown) {
        this.playVideo()
      }
    },
  },
  mounted: function () {
    window.addEventListener('mouseup', this.onMouseUp)
  },
  beforeUnmount: function () {
    window.removeEventListener('mouseup', this.onMouseUp)
  },
  methods: {
    updateMarkers: function() {
      this.markers = []
      const detections = this.annotationReportDetections
      if (!detections) return
      detections.forEach((v)=>{
        const entity = v?.entity?.description
        let icon = ""
        switch (entity) {
          case "vehicle":
            icon = "directions_car"
            break
          case "person":
            icon = "directions_walk"
            break
          default:
            return
        }
        const startFrame = v?.lifespan?.start_frame
        const endFrame = v?.lifespan?.end_frame
        const left = `${startFrame / this.totalFrames * 100}%`
        const width = `${(endFrame - startFrame) / this.totalFrames * 100}%`
        const marker = {
          startFrame,
          icon,
          left,
          width,
          top: "8px",
          height: "34px",
        }
        this.markers.push(marker)
      })
      // SORT - for better visibility
      this.markers.sort((a,b)=>{
        if (a.startFrame < b.startFrame) return -1
        if (a.startFrame > b.startFrame) return 1
        return 0;
      });
    },
    onMouseUp: function() {
      this.isMouseDown = false
    },
    onCurrentTimeChange: function() {
      if (!this.video) return
      if (!this.videoInfo) return
      this.totalFrames = this.videoInfo.nb_samples
      this.duration = this.video.duration
      this.currentTime = this.video.currentTime
    },
    togglePlay: function() {
      switch (this.play) {
        case false:
          this.playVideo()
          break
        case true:
          this.pauseVideo()
          break
      }
    },
    onMouseMove: function (e) {
      if (!this.isMouseDown) return
      this.onClickTracks(e)
    },
    onClickTracks: function (e) {
      if (!(this.$refs['tracks-wrapper'] instanceof Object)) return
      const rect = this.$refs['tracks-wrapper'].getBoundingClientRect()
      const x = e.clientX - rect.left ?? 0
      const targetTime = this.duration * (x / rect.width)
      this.setCurrentTimeVideo(targetTime)
    },
    playVideo: function () {
      if (!this.video) return
      this.video.play()
    },
    pauseVideo: function () {
      if (!this.video) return
      this.video.pause()
    },
    setCurrentTimeVideo: function (targetTime) {
      if (!this.video) return
      const targetVideo = this.video
      targetVideo.currentTime = targetTime
    },
  },
}
</script>
