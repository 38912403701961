const tool = {
  install (app) {
    app.config.globalProperties.$tool = {
      scrollToId: function (id) {
        document.getElementById(id).scrollIntoView({behavior: "smooth"})
      },
      fileToBase64: function(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },
      parseMP4: async function (file) {
        // REF - https://gpac.github.io/mp4box.js/test/filereader.html
        // INIT - func
        const findIndexOfText = function (uint8Array, text) {
          const textUint8Array = new TextEncoder().encode(text)
          let index = -1
          for (let i=0; i<uint8Array.length; i++) {
            if (uint8Array[i+0] !== textUint8Array[0]) continue
            if (uint8Array[i+1] !== textUint8Array[1]) continue
            if (uint8Array[i+2] !== textUint8Array[2]) continue
            if (uint8Array[i+3] !== textUint8Array[3]) continue
            index = i
            break
          }
          return index
        }
        // INIT - vars
        const ab = await file.arrayBuffer()
        const view = new DataView(ab)
        const buf = new Uint8Array(ab)

        // UPDATE - nb_samples
        const stsz = findIndexOfText(buf, "stsz")
        const nb_samples = view.getUint32(stsz+12)

        // UPDATE - duration
        const mvhd = findIndexOfText(buf, "mvhd")
        const movie_timescale = view.getUint32(mvhd+17)
        const movie_duration = view.getUint32(mvhd+21)
        const duration = movie_duration / movie_timescale;

        // UPDATE - fps
        const fps = nb_samples / duration;

        // UPDATE - width, height
        const tkhd = findIndexOfText(buf, "tkhd")
        const width = view.getUint32(tkhd+(20*4))/65536
        const height = view.getUint32(tkhd+(21*4))/65536

        return {
          nb_samples,
          duration,
          fps,
          width,
          height,
        };        
      },
      isValidIPv4: function (addr) {
        return /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(addr)
      },
      isValidEmailAddress (email) {
        let regexp = new RegExp('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-\\+]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')
        if (regexp.test(email)) return true
        return false
      },
      isValidMacAddress (macAddress) {
        let regexp = new RegExp('[0-9A-F]{12}')
        if (regexp.test(macAddress)) return true
        return false
      },
      redirectToUrl: function (url) {
        // similar behavior as an HTTP redirect
        window.location.replace(url)
      },
      openNewTab: function (url) {
        let win = window.open(url, '_blank')
        // Browser has allowed it to be opened
        if (win) {
          win.focus()
          return
        } 
        // Browser has blocked it
        alert('Please allow popups for this website')
      },
      makePhoneCall: function (phoneNumber) {
        window.location.href = 'tel:' + phoneNumber
      },
      sendEmail: function (emailAddress) {
        window.location.href = 'mailto:' + emailAddress
      },
      openMap: function (lat, lng) {
        let url = 'http://maps.apple.com/?q=' + lat + ',' + lng
        this.openNewTab(url)
      },
      playTts: function (text) {
        // TTS is not supported
        if (!('speechSynthesis' in window)) return
        // TTS is supported
        let msg = new SpeechSynthesisUtterance(text)
        let voiceName = 'Google US English'
        msg.voice = window['speechSynthesis'].getVoices().filter(voice => { return voice.name === voiceName })[0]
        window['speechSynthesis'].speak(msg)
      },
      cancelTts: function () {
        // TTS is not supported
        if (!('speechSynthesis' in window)) return
        // TTS is supported
        window['speechSynthesis'].cancel()
      },
    }
  }
}

export default tool
