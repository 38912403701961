
import './styles/quasar.sass'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import './styles/material-icons.css'
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
  },
  plugins: {
    Dialog,
    Notify,
    AppFullscreen,
  }
}