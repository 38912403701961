import homeRoutes from '@/_routes/home/_router'
import videoAIRoutes from '@/_routes/video-ai/_router'
import labRoutes from '@/_routes/lab/_router'

const routes = [
    {
      path: '/',
      redirect: '/video-ai'
    },
    ...homeRoutes,
    ...videoAIRoutes,
    ...labRoutes,
]

import { createRouter, createWebHistory } from 'vue-router'
export default createRouter({
  history: createWebHistory(),
  routes,
})