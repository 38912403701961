import Table101 from "./table/Table101.vue"
import Video101 from "./video/Video101.vue"
import Video102 from "./video/Video102.vue"
import Control102 from "./control/Control102.vue"

export default {
  install(app) {
    app.component("c-table-101", Table101)
    app.component("c-video-101", Video101)
    app.component("c-video-102", Video102)
    app.component("c-control-102", Control102)
  }
}
