import { 
  ionLogoMicrosoft,
  ionLogoGoogle,
  ionLogoSlack,
  ionMegaphone,
} from '@quasar/extras/ionicons-v6'
import { 
  fasMousePointer,
} from '@quasar/extras/fontawesome-v5'

const icon = {
  install (app) {
    app.config.globalProperties.$icon = {
      ionLogoMicrosoft,
      ionLogoGoogle,
      ionLogoSlack,
      ionMegaphone,
      fasMousePointer,
    }
  }
}

export default icon
