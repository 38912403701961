<template lang="pug">

//- All
.no-scroll(
  style="position:absolute;top:0;left:0;width:100%;height:100%;")
  //- video
  .no-scroll(
    :style="cursorStyle"
    style="position:relative;width:100%;height:calc(100% - 80px);"
    @mousemove="onMouseMove")
    video(
      :src="src"
      @play="play=true"
      @pause="play=false"
      @timeupdate="timeupdated=$event"
      autoplay loop
      style="width:100%;height:100%;object-fit:fill;"
      ref="c-video-102")

    MotionBlob(
      v-show="blob"
      :style=`{
        transformOrigin: 'top left',
        transform: transform,
      }`
      style="width:100%;height:100%;"
      :video="video"
      :sensitivity="sensitivity"
      :currentFrame="currentFrame"
      :blobMode="blob")
    
    ActivityAnnotation(
      :annotate="annotate"
      style="width:100%;height:100%;")

  //- control
  div.relative-position.bg-black(style="width:100%;height:80px;")
    Slider(
      :video="video"
      :videoInfo="videoInfo"
      :play="play"
      :timeupdated="timeupdated"
      :currentFrame="currentFrame")

</template>

<style lang="sass">
</style>

<script>
import Slider from "./comps/Slider.vue"
import MotionBlob from "./comps/MotionBlob.vue"
import ActivityAnnotation from "./comps/ActivityAnnotation.vue"

export default {
  components: {
    Slider,
    MotionBlob,
    ActivityAnnotation,
  },
  emits: ["playing"],
  props: {
    file: {
      type: File,
      default: null,
    },
    sensitivity: {
      type: Number,
      default: 50,
    },
    blob: {
      type: Number,
      default: 1,
    },
    rewind: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Boolean,
      default: false,
    },
    annotate: {
      type: Boolean,
      default: false,
    },
    togglePlay: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    cursorStyle: function () {
      let cursor = "default"
      if (this.zoom) cursor = "zoom-in"
      if (this.annotate) cursor = "crosshair"
      return {cursor}
    },
  },
  data: function () {
    return {
      src: "",
      fps: 6,
      fileName: "",
      currentFrame: -1,
      updatingCurrentFrame$i: null,
      video: undefined,
      videoInfo: undefined,
      play: false,
      play$rewind: false,
      timeupdated: undefined,
      rewind$i: null,
      transform: '',
      transformCache: '',
    }
  },
  watch: {
    file: function () {
      this.init()
    },
    play: function () {
      this.$emit("playing", this.play)
    },
    fps: function () {
      this.startUpdatingCurrentFrame()
    },
    rewind: function () {
      if (!this.video) return
      // REVERSE
      if (this.rewind) {
        this.play$rewind = this.play
        this.video.pause()
        this.rewind$i = setInterval(()=>{
          this.video.currentTime -= 1/this.fps
        }, 1000/(this.fps*2))
        return
      }
      // NORMAL
      if (this.rewind$i) {
        clearInterval(this.rewind$i)
        this.rewind$i = null
      }
      // ONLY when it was playing
      if (this.play$rewind) this.video.play()
    },
    zoom: function () {
      if (!this.zoom) {
        this.transform = ''
        this.updateVideoStyle()
        return
      }
      this.transform = this.transformCache
      this.updateVideoStyle()
    },
    annotate: function () {
      if (!this.video) return
      if (this.annotate) this.video.pause()
      else this.video.play()
    },
    togglePlay: function () {
      if (!this.video) return
      if (this.play) this.video.pause()
      else this.video.play()
    },
  },
  mounted: function () {
    this.init()
  },
  beforeUnmount: function () {
    this.stopUpdatingCurrentFrame()
  },
  methods: {
    onMouseMove: function (e) {
      if (!(this.$refs['c-video-102'] instanceof Object)) return
      const rect = this.$refs['c-video-102'].offsetParent.getBoundingClientRect()
      const x = e.clientX - rect.left ?? 0
      let y = e.clientY - rect.top ?? 0
      if (y > rect.height) y = rect.height
      const scale = 3
      this.transformCache = `scale(${scale}) translate(-${x/rect.width*100/(1+(1/(scale-1)))}%, -${y/rect.height*100/(1+(1/(scale-1)))}%)`
      if (!this.zoom) return
      this.transform = this.transformCache
      this.updateVideoStyle()
    },
    // 
    init: function() {
      this.updateVideo()
      this.updateSRC()
      this.updateVideoInfo()
      this.updateFileName()
      this.startUpdatingCurrentFrame()
    },
    //
    startUpdatingCurrentFrame: function () {
      this.stopUpdatingCurrentFrame()
      this.updatingCurrentFrame$i = setInterval(()=>{
        this.updateCurrentFrame()
      }, 1000/this.fps)
    },
    stopUpdatingCurrentFrame: function () {
      if (!this.updatingCurrentFrame$i) return
      clearInterval(this.updatingCurrentFrame$i)
      this.updatingCurrentFrame$i = null
    },
    //
    updateVideo: function () {
      if (this.video) return
      if (!this.$refs['c-video-102']) return
      this.video = this.$refs['c-video-102']
    },
    updateVideoStyle: function () {
      if (!this.video) return
      this.video.style.transformOrigin = 'top left'
      this.video.style.transform = this.transform
    },
    updateCurrentFrame: function () {
      if (!(this.$refs['c-video-102'] instanceof Object)) return
      this.currentFrame = Math.floor(this.$refs['c-video-102'].currentTime * this.fps)
    },
    updateVideoInfo: async function () {
      if (!this.file) return
      const info = await this.$tool.parseMP4(this.file)
      this.fps = info.fps
      this.videoInfo = info
    },
    updateSRC: function () {
      if (!this.file) return
      this.src = URL.createObjectURL(this.file)
    },
    updateFileName: function () {
      if (!this.file) return
      this.fileName = this.file.name
    },
  },
}
</script>
