<template lang="pug">

.q-gutter-md.q-mt-md
  q-btn(
    style="width:140px;"
    align="left"
    @click="toggleBlobMode"
    :color="`${selectedBlobMode.color}`"
    :icon="rewind ? 'lens_blur' : 'lens_blur'"
    :label="`Blob ${selectedBlobMode.name}`"
    unelevated no-caps)
    q-badge(
      style="transform: translate(-10%, -60%);"
      color="black" floating)
      .text-bold Tab
  q-btn(
    style="width:140px;"
    align="left"
    @click="rewind = !rewind"
    :color="rewind ? 'primary' : 'grey'"
    :icon="rewind ? 'fast_rewind' : 'fast_rewind'"
    :label="rewind ? 'Rewind on' : 'Rewind off'"
    unelevated no-caps)
    q-badge(
      style="transform: translate(-10%, -60%);"
      color="black" floating)
      .text-bold Shift
  //- q-chip(
  //-   square
  //-   outline
  //-   color="primary"
  //-   text-color="white"
  //-   icon="pause") Pause [Space]
  q-btn(
    style="width:140px;"
    align="left"
    @click="zoom = !zoom"
    :color="zoom ? 'primary' : 'grey'"
    :icon="zoom ? 'zoom_in' : 'zoom_in'"
    :label="zoom ? 'Zoom on' : 'Zoom off'"
    unelevated no-caps)
    q-badge(
      style="transform: translate(-10%, -60%);"
      color="black" floating)
      .text-bold Z
  q-btn(
    style="width:140px;"
    align="left"
    @click="annotate = !annotate"
    :color="annotate ? 'primary' : 'grey'"
    :icon="annotate ? 'crop_free' : 'crop_free'"
    :label="annotate ? 'Annotate' : 'Annotate'"
    unelevated no-caps)
    q-badge(
      style="transform: translate(-10%, -60%);"
      color="black" floating)
      .text-bold A

</template>

<style>
</style>

<script>
export default {
  props: {
    playing: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["blob", "rewind", "zoom", "annotate", "toggle-play"],
  computed: {
    blobModes: function () {
      return [
        {index: 0, name: "off", color: "grey"},
        {index: 1, name: "soft", color: "primary"},
        {index: 2, name: "hard", color: "red"},
      ]
    },
    selectedBlobMode: function () {
      return this.blobModes[this.blob]
    },
  },
  data: function () {
    return {
      blob: 1,
      rewind: false,
      zoom: false,
      annotate: false,
      togglePlay: 0,
    }
  },
  watch: {
    playing: function () {
      if (!this.playing) return
      this.annotate = false
    },
    blob: function () {
      this.$emit("blob", this.blob)
    },
    rewind: function () {
      this.$emit("rewind", this.rewind)
    },
    zoom: function () {
      this.$emit("zoom", this.zoom)
    },
    annotate: function () {
      this.$emit("annotate", this.annotate)
    },
    togglePlay: function () {
      this.$emit("toggle-play", this.togglePlay)
    },
  },
  mounted: function () {
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
    this.emitsAll()
  },
  beforeUnmount: function () {
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    emitsAll: function () {
      this.$emit("blob", this.blob)
      this.$emit("rewind", this.rewind)
      this.$emit("zoom", this.zoom)
    },
    toggleBlobMode: function () {
      let mode = this.blob
      mode++
      if (mode > this.blobModes.length - 1) mode = 0
      this.blob = mode
    },
    onKeyDown: function (e) {
      if (e.repeat) return
      switch (e.keyCode) {
        // SHIFT: Rewind
        case 16:
          e.preventDefault()
          this.rewind = true
          break
        // Z: Zoom
        case 90:
          e.preventDefault()
          this.zoom = true
          break
        // TAB: BlobMode
        case 9:
          e.preventDefault()
          this.toggleBlobMode()
          break
        // A: Annotate
        case 65:
          e.preventDefault()
          // this.annotate = true
          break
        // P: Play/Pause
        case 80:
          e.preventDefault()
          break
      }
    },
    onKeyUp: function (e) {
      if (e.repeat) return
      switch (e.keyCode) {
        // SHIFT: Rewind
        case 16:
          e.preventDefault()
          this.rewind = false
          break
        // Z: Zoom
        case 90:
          e.preventDefault()
          this.zoom = false
          break
        // TAB: BlobMode
        case 9:
          e.preventDefault()
          break
        // A: Annotate
        case 65:
          e.preventDefault()
          this.annotate = !this.annotate
          break
        // P: Play/Pause
        case 80:
          e.preventDefault()
          this.togglePlay++
          break
      }
    },
  },
}
</script>
