<template lang="pug">
.absolute(
  style="top:0;left:0;")
  canvas(
    ref="motion-blob-canvas"
    style="width:100%;height:100%;")
</template>

<style>
</style>

<script>
export default {
  props: {
    video: {
      type: Object,
      default: undefined,
    },
    sensitivity: {
      type: Number,
      default: 50,
    },
    currentFrame: {
      type: Number,
      default: 0,
    },
    blobMode: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    minAbsDiff: function() {
      let minDiff = 100 - this.sensitivity
      if (minDiff < 5) minDiff = 5
      if (minDiff > 100) minDiff = 100
      return minDiff
    },
    maxActivityFrames: function() {
      return 2
    },
    canvasWidth: function() {
      return 200
    },
  },
  data: function () {
    return {
      previousFrame: null,
      activityFrames: [],
    }
  },
  watch: {
    currentFrame: function () {
      this.updateCanvas()
    },
  },
  mounted: function () {
    this.updateCanvas()
  },
  methods: {
    updateCanvas: function () {
      // GET - frameToProcess
      const frameToProcess = this.extractFrameFromVideo()
      if (!frameToProcess) return
      // CONVERT frameToProcess - rgb to grayscale
      this.rgbToGrayscale(frameToProcess.data)
      
      // CASE 1 - no previousFrame to compare
      if (!(this.previousFrame instanceof Object)) {
        this.previousFrame = frameToProcess
        return
      }

      // CASE 2 - has previousFrame to compare
      const activityFrame = this.getAbsDiffMaskingFrame(frameToProcess.data, this.previousFrame.data)
      // DONE - compare
      this.previousFrame = frameToProcess
      // UPDATE - blob canvas
      const frameToShow = this.generateBlobMaskingFrameAndRecycle(activityFrame)
      if (!frameToShow) return
      this.updateBlobCanvas(frameToShow)
    },
    //
    updateBlobCanvas: function (frameToShow) {
      if (!this.video) return
      if (!(this.$refs['motion-blob-canvas'] instanceof Object)) return
      const canvas = this.$refs['motion-blob-canvas']
      const ctx = canvas.getContext('2d')
      canvas.width = this.canvasWidth
      canvas.height = this.video.videoHeight * (this.canvasWidth / this.video.videoHeight)
      ctx.putImageData(frameToShow, 0, 0)
    },
    extractFrameFromVideo: function () {
      if (!this.video) return
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = this.canvasWidth
      canvas.height = this.video.videoHeight * (this.canvasWidth / this.video.videoHeight)
      ctx.drawImage(this.video, 0, 0, canvas.width, canvas.height)
      const frame = ctx.getImageData(0, 0, canvas.width, canvas.height)
      return frame
    },
    rgbToGrayscale: function (data) {
      if (!data.length) return
      for(let i=0; i < data.length; i += 4) {
        // luma, Rec 709
        const luma = data[i] * 0.2126 + data[i+1] * 0.7152 + data[i+2] * 0.0722
        data[i] = data[i+1] = data[i+2] = luma
        data[i+3] = data[i+3]
      }
    },
    createEmptyFrame: function () {
      if (!this.video) return
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = this.canvasWidth
      canvas.height = this.video.videoHeight * (this.canvasWidth / this.video.videoHeight)
      return ctx.createImageData(canvas.width, canvas.height)
    },
    getAbsDiffMaskingFrame: function (data1, data2) {
      if (!data1.length) return
      if (!data2.length) return
      if (data1.length !== data2.length) return
      const frame = this.createEmptyFrame()
      if (!(frame instanceof Object)) return
      const data = frame.data
      let changeCount = 0
      for(let i=0; i < data1.length; i += 4) {
        const diff = Math.abs(data1[i] - data2[i])
        if (diff < this.minAbsDiff) {
          data[i] = data[i+1] = data[i+2] = 255
          data[i+3] = 0
          continue
        }
        data[i] = data[i+1] = data[i+2] = 0
        data[i+3] = 255
        changeCount++
      }
      // FILTER out - lighting change
      //            - it is better to view the raw image if there is too much pixel changes
      if (((changeCount / (data1.length / 4)) * 100) > 90) {
        for(let i=0; i < data1.length; i += 4) {
          data[i] = data[i+1] = data[i+2] = 255
          data[i+3] = 0
        }
      }
      return frame
    },
    generateBlobMaskingFrameAndRecycle: function (newActivityFrame) {
      this.activityFrames.push(newActivityFrame)
      const maskingFrame = this.combineActivityFrames(this.activityFrames)
      // RECYCLE
      if (this.activityFrames.length > this.maxActivityFrames) {
        this.activityFrames.shift()
      }
      return maskingFrame
    },
    combineActivityFrames: function (activityFrames) {
      if (activityFrames.length < this.maxActivityFrames) return
      const frame = this.createEmptyFrame()
      const data = frame.data
      for(let i=0; i < data.length; i += 4) {
        let changeCount = 0
        for(let j=0; j < activityFrames.length; j++) {
          const activityFrame = activityFrames[j]
          if(!activityFrame) continue
          const activityData = activityFrame.data
          // HAS - activity
          if (activityData[i] !== 255) changeCount++
        }

        // CASE 1 - 0:Blob-Off, 1:Blob-Soft
        if (this.blobMode < 2) {
          // CASE 1-1 - NO activity
          if (changeCount < 1) {
            data[i] = data[i+1] = data[i+2] = data[i+3] = 0
            continue
          }
          // CASE 2-2 - YES activity - Blue
          const opacity = 0.5
          data[i] = 135
          data[i+1] = 206
          data[i+2] = 235
          data[i+3] = 255 * opacity
        }
        // CASE 2 - 2:Blob-Hard
        else {
          // CASE 2-1 - NO activity - White
          if (changeCount < 1) {
            const opacity = 0.8
            data[i] = data[i+1] = data[i+2] = 255
            data[i+3] = 255 * opacity
            continue
          }
          // CASE 1-2 - YES activity - Red
          const opacity = 1
          data[i] = 255
          data[i+1] = 0
          data[i+2] = 0
          data[i+3] = 255 * opacity
        }
      }
      return frame
    },
    getRainbowColor: function (sourceIndex) {
      const singleColorWidth = 5
      const targetIndex = ((sourceIndex+4)/4) % this.canvasWidth
      const rainbow = [
        [255, 0, 0], // RED
        [255, 127, 0], // ORANGE
        [255, 255, 0], // YELLOW
        [0, 255, 0], // GREEN
        [0, 0, 255], // BLUE
        // [75, 0, 130], // NAVY
        // [148, 0, 211], // PURPLE
      ]
      const index = Math.ceil(targetIndex/singleColorWidth) % rainbow.length
      return rainbow[index]
    },
  },
}
</script>
