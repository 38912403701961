<template lang="pug">

router-view

</template>

<style lang="sass">
.q-table .material-icons
  display: inline !important
</style>

<script>
export default {
}
</script>
