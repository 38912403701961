const env = {
  install (app) {
    const getCurrentDomain = function () {
      return window.location.hostname
    }
    const domains = {
      'dev': 'chektdev.com',
      'prod': 'chekt.com',
    }
    app.config.globalProperties.$env = {
      isProduction: function () {
        const domain = getCurrentDomain()+''
        if (domain.includes('chekt.com')) return true
        if (domain.includes('chekt.name')) return true
        if (domain.includes('chektdev.com')) return true
        if (domain.includes('app.local')) return true
        if (domain.includes('localhost')) return false
        return true
      },
      getAPIPrefixV1_1: function () {
        if (this.isProduction()) return `https://api.${domains.prod}/api/v1.1`
        return `https://api.${domains.dev}/api/v1.1`
      },
      getAPIPrefixV2: function () {
        if (this.isProduction()) return `https://api.${domains.prod}/api/v2`
        return `https://api.${domains.dev}/api/v2`
      },
      getVideoAIAPIPrefixV1: function () {
        // original "https://us-central1-chekt-app-bo-nonprod.cloudfunctions.net/videoai/api/v1"
        // to "https://chekt-w-bo-landing-nonprod.web.app/api/v1"
        // to "https://lab.chekt.com/api/v1"
        if (this.isProduction()) return "https://lab.chekt.com/api/v1"
        return "http://localhost:3005/chekt-app-bo-nonprod/us-central1/videoai/api/v1"
      },
    }
  }
}

export default env
